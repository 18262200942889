<script>
import BottomNav from '@/components/Themes/dayonedayone/BottomNav'
import CentralLoader from '@/components/Loaders/CentralLoader';
import UpdateProfileModal from '@/components/Themes/dayonedayone/Modals/UpdateProfile';
import TnC from '@/components/Themes/dayonedayone/Loyalty/TnC';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: ['installable'],
  components: {
    BottomNav,
    CentralLoader,
    UpdateProfileModal,
    TnC,
  },
  metaInfo() {
    return {
      title: `${this.profile.restaurant_name}`,
      titleTemplate: null,
      meta: [
        { name: 'og:url', property: 'og:url', content: `https://${this.profile.restaurant_hostname}` },
        { name: 'og:site_name', property: 'og:site_name', content: this.profile.restaurant_name },
        { name: 'og:title', property: 'og:title', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:description', property: 'og:description', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:image', property: 'og:image', content: this.merchantLogo },
        { name: 'og:type', property: 'og:type', content: 'website' },
        { name: 'og:updated_time', property: 'og:updated_time', content: Date.now() },
      ],
    }
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      isLoading: false,
      updateProfileModal: false,
      dialogTnC: false,
    };
  },
  computed: {
    ...mapState('loyalty', {
      tiers: (state) => state.tiers,
      vouchers: (state) => state.vouchers,
      loyaltyCustomer: (state) => state.customer,
      pointBalance: (state) => state.pointBalance,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('client', {
      name: 'getName',
    }),
    ...mapGetters('merchant', {
      hasBranch: ['hasBranch'],
    }),
    merchantLogo() {
      if (!this.profile.logo_s3 && !this.profile.logo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return this.profile.logo_s3 ? (`${this.cdnUrl}/${this.profile.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.profile.logo}`);
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('loyalty', ['getLoyaltyVouchers', 'getLoyaltyTiers', 'getLoyaltyPointBalance']),
    onLogout() {
      window.location.href = '/';
    },
    goTo(name) {
      this.$router.push({ name });
    },
    isCurrentTier(tier) {
      return this.loyaltyCustomer?.loyaltyTier?.id === tier.id;
    },
    openWhatsApp(contactPhone) {
      window.open(((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? 'https://api.whatsapp.com/send?phone='
        : 'https://web.whatsapp.com/send?phone=')
        + contactPhone), '_blank');
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route);

    this.getLoyaltyVouchers({
      merchantId: this.profile.merchant_id,
    });

    this.getLoyaltyPointBalance({});

    // Load LoyaltyTiers
    this.getLoyaltyTiers({
      merchantId: this.profile.merchant_id,
    });
  },
};
</script>
<template>
  <v-card class="home-sec p-4" elevation="0">
    <update-profile-modal
      :visible="updateProfileModal"
      @close="updateProfileModal = false"
    ></update-profile-modal>
    <h6 class="text-body-2 text-center dark--text font-weight-bold">
      <v-icon color="tertiary">mdi-map-marker</v-icon>
      {{ profile.restaurant_name }}
    </h6>
    <v-img
      contain
      class="text-center mx-auto mt-3 px-0"
      width="100%"
      position="center"
      src="@/assets/dayonedayone/images/profile-loyalty-card.webp"
      @click="updateProfileModal = true"
    >
      <div class="profile-customer-name position-absolute">
        <div class="text-body-2 text-left tertiary--text font-weight-bold">{{ name }}</div>
        <div class="text-caption text-left label--text font-weight-6">{{ loyaltyCustomer.contactPhone }}</div>
      </div>
    </v-img>
    <CentralLoader v-if="isLoading || !profile" color="white"></CentralLoader>
    <div class="text-right primary--text font-weight-bold font-10 mt-4 mb-1">
      <router-link
        :to="{ path: '/loyalty/tier' }"
      >
        See Tiers &gt;
      </router-link>
    </div>
    <v-card class="container-loyalty-tier rounded-lg" elevation="0">
      <v-container class="p-0">
        <v-row justify="space-around" class="m-0 p-0">
          <v-col class="d-flex flex-column text-overline text-center font-weight-6 px-5 pb-1" v-for="tier of tiers" :key="`col-loyalty-tier-${tier.id}`" :cols="12 / tiers.length">
            <v-img
              contain
              class="text-center mx-auto"
              position="center"
              height="50px"
              :src="`${cdnUrl}/assets/images/loyalty/${profile.hq_merchant_id}/tiers/${tier.sortOrder}.webp`"
            ></v-img>
            <div
              class="font-10"
              :class="{ 'tertiary--text font-weight-bold': isCurrentTier(tier) }"
            >
              {{ tier.name }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="container-loyalty-benefit rounded-lg mt-4" elevation="0" v-if="loyaltyCustomer">
      <v-container class="p-0">
        <v-row class="m-0 p-0">
          <v-col cols="3">
            <v-img
              contain
              position="center right"
              height="40"
              src="@/assets/dayonedayone/images/profile-loyalty-point.webp"
            ></v-img>
          </v-col>
          <v-col class="d-flex flex-column font-weight-bold pl-0" cols="4">
            <router-link
              :to="{ path: '/loyalty/points' }"
            >
              <div class="font-10 font-weight-6">eslite Points</div>
              <div class="font-10">
                <span class="font-20">{{ pointBalance }}</span> pts
              </div>
            </router-link>
          </v-col>
          <v-col cols="2">
            <v-img
              contain
              position="center right"
              height="40"
              src="@/assets/dayonedayone/images/profile-loyalty-voucher.webp"
            ></v-img>
          </v-col>
          <v-col class="d-flex flex-column font-weight-bold pl-0" cols="3">
            <router-link
              :to="{ path: '/loyalty/reward?default=1' }"
            >
              <div class="font-10 font-weight-6">Vouchers</div>
              <div class="font-10">
                <span class="font-20">{{ vouchers.length }}</span> pcs
              </div>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-list class="rounded-lg mt-4 py-0" dense>
      <v-list-item @click="updateProfileModal = true">
        <v-list-item-content>
          <v-list-item-title class="font-weight-6">Edit Profile</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-list class="rounded-lg mt-2 py-0" dense>
      <v-list-item @click="goTo('orders')">
        <v-list-item-content>
          <v-list-item-title class="font-weight-6">Order History</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-list class="rounded-lg mt-2 py-0" dense>
      <v-list-item @click="dialogTnC = true;">
        <v-list-item-content>
          <v-list-item-title>Terms & Conditions</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-dialog v-model="dialogTnC" width="400px" class="h-100">
        <TnC dialog="true" @close="dialogTnC = false;"></TnC>
      </v-dialog>
    </v-list>
    <v-list class="rounded-lg mt-2 py-0" dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Privacy Policy</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-list class="rounded-lg mt-2 py-0" dense>
      <v-list-item @click="openWhatsApp(profile.contact_phone)">
        <v-list-item-content>
          <v-list-item-title>Contact Us</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-list class="rounded-lg mt-2 py-0" dense>
      <v-list-item @click="logout(onLogout)">
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <!-- <v-card elevation="0" style="background-color: transparent; height: 56px;"></v-card> -->
    <!-- <v-divider class="footer-divider m-0 p-0"></v-divider> -->
    <BottomNav :fixed="true"/>
  </v-card>
</template>
<style scoped>
.home-sec {
  background-image: url('~@/assets/dayonedayone/images/profile-background.webp');
  background-size: contain;
  background-position: bottom;
  background-color: #f7efe8;
  min-height: 100dvh;
  padding-bottom: 56px !important;
}

.container-loyalty-tier, .container-loyalty-benefit {
  background-color: #e1cfc7;
}

.footer-divider {
  background-color: rgb(249, 244, 236);
}

.profile-customer-name {
  left: 24px;
  bottom: 12px;
}
</style>
