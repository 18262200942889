<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'UpdateProfile',
  props: ['visible'],
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      countryCode: process.env.VUE_APP_COUNTRY_CODE,
      updateForm: {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmation: '',
        merchantId: 0,
      },
      isLoading: false,
      showPassword: false,
      success: '',
      error: '',
    };
  },
  computed: {
    ...mapState('customer', ['customer']),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('client', {
      name: 'getName',
    }),
    show: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close', false);
        }
      },
    },
  },
  methods: {
    ...mapActions('client', {
      updateClient: 'update',
    }),
    ...mapActions('data', ['createDataAnalyticEvent']),
    ...mapActions('log', ['createLog']),
    async cancel() {
      this.$gtag.event('update-profile-cancel', {
        event_category: 'interaction',
        event_label: 'Update Profile (Cancel)',
        event_value: `Email: ${this.updateForm.email}, Name: ${this.updateForm.firstName}`,
      });

      this.show = false;
    },
    async update() {
      this.isLoading = true;

      if (!(await this.$refs.form.validate())) {
        this.isLoading = false;
        return;
      }

      try {
        this.$gtag.event('update-profile-success', {
          event_category: 'engagement',
          event_label: 'Update Profile (Success)',
          event_value: `Email: ${this.updateForm.email}, Name: ${this.updateForm.firstName}`,
        });

        await this.updateClient(this.updateForm);

        this.isLoading = false;
        this.success = 'You have successfully update your profile.';
        setTimeout(() => (this.success = ''), 5000);
      } catch (e) {
        this.createLog({
          type: 'error',
          data: {
            app: 'pwa',
            nav: 'profile.update',
            merchantId: this.profile?.merchant_id ?? 0,
            message: e.message,
            refTable: 'uuid',
            refId: localStorage.uuid,
          }
        });

        console.log(e.message);
        this.isLoading = false;
        this.error = e.message;
        setTimeout(() => (this.error = null), 5000);
      }
    },
  },
  mounted() {
  },
  watch: {
    show: function (val) { 
      if (val) {
        this.updateForm.merchantId = this.profile.merchant_id;
        this.updateForm.firstName = this.customer.firstName;
        this.updateForm.email = this.customer.email;
      }
    }
  }
};
</script>
<template>
  <v-dialog
    content-class="page-update-profile"
    v-model="show"
    transition="dialog-bottom-transition"
    persistent
  >
    <ValidationObserver ref="form">
      <form>
        <v-card color="#f7efe8" elevation="0">
          <v-card-title class="justify-center h4 font-weight-bold pb-2">
            <span style="color:#122944;">Update</span> <span class="tertiary--text ml-2">your profile</span>
          </v-card-title>
          <v-card-text class="text-center pb-3">
            <v-row class="px-3">
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Name"
                  :rules="{ required: true, min: 3, max: 30 }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="updateForm.firstName"
                    class="update-form p-0 m-0"
                    placeholder="Name"
                    label="Name"
                    color="secondary"
                    autocomplete="first-name"
                    height="30"
                    flat
                    rounded
                    solo
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="updateForm.email"
                    class="update-form p-0 m-0"
                    placeholder="E-mail Address"
                    label="Email"
                    color="primary"
                    autocomplete="email"
                    height="30"
                    flat
                    rounded
                    solo
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  vid="password"
                  name="Password"
                  :rules="{ min: 8 }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="updateForm.password"
                    class="update-form p-0 m-0"
                    placeholder="Password"
                    label="Password"
                    color="primary"
                    autocomplete="off"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :error-messages="errors"
                    :success="valid"
                    height="30"
                    flat
                    rounded
                    solo
                    hide-details
                    @click:append="showPassword = !showPassword"
                  >
                  </v-text-field>
                  <ul
                    class="text-caption text-left error--text mt-2"
                    v-if="errors.length > 0"
                  >
                    <li>Min 8 characters</li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="px-5 pb-1">
                <ValidationProvider
                  name="Confirm"
                  :rules="{ required: this.password, confirmed: 'password' }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="updateForm.confirmation"
                    class="update-form p-0 m-0"
                    placeholder="Confirm your password"
                    label="Confirm"
                    color="primary"
                    autocomplete="off"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :hide-details="errors.length === 0"
                    :error-messages="errors"
                    :success="valid"
                    height="30"
                    flat
                    rounded
                    solo
                    @click:append="showPassword = !showPassword"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="mt-3 px-3">
              <v-col cols="6" class="pl-10">
                <v-btn
                  :disabled="isLoading"
                  class="update-profile-btn-cancel text-caption font-weight-6 white--text py-4"
                  color="tertiary"
                  block
                  @click="cancel()"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="6" class="pr-10">
                <v-btn
                  :disabled="isLoading"
                  class="update-profile-btn-submit text-caption font-weight-6 white--text py-4"
                  color="tertiary"
                  block
                  @click="update()"
                  >Update</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </form>
      <v-alert
        v-if="success"
        class="alert-global text-body-2 white--text p-2"
        type="success"
        transition="fade-transition"
        dense
        >{{ success }}</v-alert
      >
      <v-alert
        v-if="error"
        class="alert-global text-body-2 white--text p-2"
        type="error"
        transition="fade-transition"
        dense
        >{{ error }}</v-alert
      >
    </ValidationObserver>
  </v-dialog>
</template>
<style scoped>
.page-update-profile /deep/ .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  border: none !important;
  background: #f0d2b7 !important;
  z-index: 1 !important;
}

.page-update-profile /deep/ .update-profile-btn-cancel, 
.page-update-profile /deep/ .update-profile-btn-submit {
  background-color: var(--v-tertiary) !important;
  color: #fff !important;
  z-index: 1;
}

.update-form {
  color: var(--v-dark) !important;
  font-size: 12px;
  font-weight: 600;
}

.update-form ::placeholder {
  font-size: 12px;
  font-weight: 400;
}

.alert-global {
  position: fixed;
  z-index: 999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(90vw - 48px);
}

@media screen and (min-width: 1024px) {
  .alert-global {
    width: 326px;
  }
}
</style>