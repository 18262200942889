var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"content-class":"page-update-profile","transition":"dialog-bottom-transition","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{ref:"form"},[_c('form',[_c('v-card',{attrs:{"color":"#f7efe8","elevation":"0"}},[_c('v-card-title',{staticClass:"justify-center h4 font-weight-bold pb-2"},[_c('span',{staticStyle:{"color":"#122944"}},[_vm._v("Update")]),_vm._v(" "),_c('span',{staticClass:"tertiary--text ml-2"},[_vm._v("your profile")])]),_c('v-card-text',{staticClass:"text-center pb-3"},[_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":{ required: true, min: 3, max: 30 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"update-form p-0 m-0",attrs:{"placeholder":"Name","label":"Name","color":"secondary","autocomplete":"first-name","height":"30","flat":"","rounded":"","solo":"","hide-details":errors.length === 0,"error-messages":errors,"success":valid},model:{value:(_vm.updateForm.firstName),callback:function ($$v) {_vm.$set(_vm.updateForm, "firstName", $$v)},expression:"updateForm.firstName"}})]}}])})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"update-form p-0 m-0",attrs:{"placeholder":"E-mail Address","label":"Email","color":"primary","autocomplete":"email","height":"30","flat":"","rounded":"","solo":"","hide-details":errors.length === 0,"error-messages":errors,"success":valid},model:{value:(_vm.updateForm.email),callback:function ($$v) {_vm.$set(_vm.updateForm, "email", $$v)},expression:"updateForm.email"}})]}}])})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"password","name":"Password","rules":{ min: 8 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"update-form p-0 m-0",attrs:{"placeholder":"Password","label":"Password","color":"primary","autocomplete":"off","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"error-messages":errors,"success":valid,"height":"30","flat":"","rounded":"","solo":"","hide-details":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.updateForm.password),callback:function ($$v) {_vm.$set(_vm.updateForm, "password", $$v)},expression:"updateForm.password"}}),(errors.length > 0)?_c('ul',{staticClass:"text-caption text-left error--text mt-2"},[_c('li',[_vm._v("Min 8 characters")])]):_vm._e()]}}])})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Confirm","rules":{ required: this.password, confirmed: 'password' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"update-form p-0 m-0",attrs:{"placeholder":"Confirm your password","label":"Confirm","color":"primary","autocomplete":"off","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"hide-details":errors.length === 0,"error-messages":errors,"success":valid,"height":"30","flat":"","rounded":"","solo":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.updateForm.confirmation),callback:function ($$v) {_vm.$set(_vm.updateForm, "confirmation", $$v)},expression:"updateForm.confirmation"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-3 px-3"},[_c('v-col',{staticClass:"pl-10",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"update-profile-btn-cancel text-caption font-weight-6 white--text py-4",attrs:{"disabled":_vm.isLoading,"color":"tertiary","block":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")])],1),_c('v-col',{staticClass:"pr-10",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"update-profile-btn-submit text-caption font-weight-6 white--text py-4",attrs:{"disabled":_vm.isLoading,"color":"tertiary","block":""},on:{"click":function($event){return _vm.update()}}},[_vm._v("Update")])],1)],1)],1)],1)],1),(_vm.success)?_c('v-alert',{staticClass:"alert-global text-body-2 white--text p-2",attrs:{"type":"success","transition":"fade-transition","dense":""}},[_vm._v(_vm._s(_vm.success))]):_vm._e(),(_vm.error)?_c('v-alert',{staticClass:"alert-global text-body-2 white--text p-2",attrs:{"type":"error","transition":"fade-transition","dense":""}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }